<template>
  <div>
    <BaseLoadingBox :pending="isPendingPhone">
      <ValidationObserver
        v-if="step === 1"
        id="phoneForm"
        ref="phoneForm"
        tag="form"
        @submit.prevent="handleSubmitPhone">
        <div class="mb-s">
          <p>
            Укажите номер телефона, мы отправим голосовое сообщение с кодом
            подтверждения
          </p>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <label
              for="phone"
              class="form-label text-size-xs"
              :class="{
                'form-label--disabled': !isEdit,
                'is-required': required,
              }">
              Телефон
            </label>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-m">
            <BaseInput
              id="phone"
              v-model="phone"
              :format="{
                phone: true,
                phoneRegionCode: 'RU',
              }"
              :disabled="!isEdit"
              name="phone"
              type="tel"
              placeholder="Номер телефона"
              mode="lazy"
              :rules="{
                required: required,
              }">
              <template #append>
                <BaseIcon
                  v-if="!isEdit"
                  class="input-icon"
                  glyph="edit"
                  title="Изменить телефон"
                  tabindex="0"
                  @click.prevent.stop="isEdit = !isEdit"
                  @keydown.enter="isEdit = !isEdit" />
              </template>
            </BaseInput>

            <div
              v-if="errorPhone"
              class="form-error">
              {{ errorPhone }}
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-m">
            <div
              v-if="phoneConfirmed && !isEdit"
              class="confirm-tag">
              <BaseIcon
                glyph="done"
                width="18px" />
              Подтверждено
            </div>
            <BaseButton
              v-if="isEdit"
              :disabled="!phone"
              type="submit"
              aria-label="Отправить смс с кодом"
              >Подтвердить</BaseButton
            >
          </div>
          <div class="col-xs-12">
            <div
              v-if="isEdit && infoText"
              class="text-size-normal color-gray-300"
              v-html="infoText"></div>
          </div>
        </div>
      </ValidationObserver>
    </BaseLoadingBox>

    <BaseLoadingBox :pending="isPendingCode">
      <ValidationObserver
        v-if="step === 2"
        id="codeForm"
        ref="codeForm"
        tag="form"
        @submit.prevent="handleSubmitCode">
        <div class="row">
          <div class="col-xs-12">
            <label
              for="code"
              class="form-label text-size-xs">
              Код отправлен на номер
              <a
                href="#"
                class="link tel-edit"
                @click.prevent="step = 1"
                >{{ phone }}
                <BaseIcon
                  glyph="edit"
                  width="16px"
                  height="13px" /></a
              ><br />
              Введите код из СМС:
            </label>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-m">
            <BaseInput
              id="code"
              v-model="code"
              name="code"
              inputmode="numeric"
              pattern="[0-9]*"
              autocomplete="one-time-code"
              :format="{
                blocks: [codeLength],
              }" />

            <div class="form-label text-size-xs mt-m">
              Не пришло СМС?<br />
              <span v-if="timer">
                Запросить код повторно можно через:
                <span class="color-primary">{{ time }} сек</span>
              </span>

              <span v-else>
                <a
                  href="#"
                  class="link link--pseudo"
                  @click.prevent="handleGetCodeAgain">
                  Отправить проверочный код еще раз.
                </a>
              </span>
            </div>
            <div
              v-if="errorCode"
              class="form-error">
              {{ errorCode }}
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-m">
            <BaseButton
              :disabled="!code"
              type="submit"
              aria-label="Подтвердить телефон"
              >Подтвердить</BaseButton
            >
          </div>
        </div>
      </ValidationObserver>
    </BaseLoadingBox>
  </div>
</template>

<script>
const STORAGE_SMS = "sms_state";
const SMS_CODE_LENGHT = 4;
const SMS_CODE_LIFETIME_SECONDS = 120;
const SMS_CODE_CREATE_MAX_RETRIES = 5;

import { talentRequest } from "@/services/api";

export default {
  name: "SetPhone",
  props: {
    infoText: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step: 1, // 1, 2
      isEdit: false,
      phone: "",
      code: "",
      errorPhone: "",
      errorCode: "",
      isPendingPhone: false,
      isPendingCode: false,
      time: 0,
      timer: null,
      codeLength: SMS_CODE_LENGHT,
      codeLifetime: SMS_CODE_LIFETIME_SECONDS,
      countOfGetCode: SMS_CODE_CREATE_MAX_RETRIES,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.talentUser;
    },
    phoneConfirmed() {
      if (!this.user) return;
      return this.user.phone_confirmed;
    },
  },
  created() {
    const { user, codeLifetime } = this;
    if (!user) this.isEdit = true;
    else {
      this.phone = user.phone;
      if (!user.phone_confirmed) {
        this.isEdit = true;
      }
    }

    const restoredState = JSON.parse(localStorage.getItem(STORAGE_SMS));

    if (restoredState?.phone !== this.phone) return;
    const diff = Math.floor((Date.now() - restoredState.time) / 1000);

    if (diff < codeLifetime) {
      this.startTimer(codeLifetime - diff);
      this.countOfGetCode = restoredState.countOfGetCode;
    }
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async handleSubmitPhone() {
      if (this.isPendingPhone) return;
      const { phone, codeLifetime, user } = this;

      if (phone) {
        this.errorPhone = "";
        this.errorCode = "";

        // Если телефон совпадает с тем, что в профиле и он был уже подтвержден
        if (
          user &&
          phone === user.phone.replace(/-/g, " ") &&
          user.phone_confirmed
        ) {
          this.isEdit = false;
          return;
        }

        // Проверим, не запрашивали ли уже код
        const restoredState = JSON.parse(localStorage.getItem(STORAGE_SMS));
        if (restoredState) {
          const diff = Math.floor((Date.now() - restoredState.time) / 1000);
          if (restoredState.phone === phone && diff < codeLifetime) {
            // Если телефон не изменился, то просто переходим на следующий шаг
            this.step = 2;
            return;
          } else {
            // Если телефон изменился или уже прошло достаточно времени, то запросим новый код
            localStorage.removeItem(STORAGE_SMS);
            this.clearTimer();
          }
        }

        this.isPendingPhone = true;
        try {
          await talentRequest({
            url: "/api/sms/check-phone/",
            method: "POST",
            data: { phone },
          });
          this.getCode("errorPhone");
          this.$emit("introduced-phone");
        } catch (error) {
          this.errorPhone = error.message;
        }
        this.isPendingPhone = false;
      }
    },
    // В параметре указываем в какое поле выводить ошибку
    async getCode(errorField) {
      if (this.timer) return;
      const { phone, codeLifetime } = this;
      try {
        const { data } = await talentRequest({
          url: "/api/sms/",
          method: "POST",
          data: { phone },
        });
        this.countOfGetCode--;
        localStorage.setItem(
          STORAGE_SMS,
          JSON.stringify({
            phone,
            session_token: data.session_token,
            time: Date.now(),
            countOfGetCode: this.countOfGetCode,
          })
        );
        this.startTimer(codeLifetime);
        this.step = 2;
      } catch (error) {
        this[errorField] = error.message;
      }
    },
    startTimer(start) {
      if (this.timer) return;
      this.time = start;
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          this.clearTimer();
        }
      }, 1000);
    },
    clearTimer() {
      clearInterval(this.timer);
      this.time = 0;
      this.timer = null;
    },
    handleGetCodeAgain() {
      this.getCode("errorCode");
    },
    async handleSubmitCode() {
      const { phone, code } = this;
      if (code) {
        this.isPendingCode = true;
        try {
          const { session_token } = JSON.parse(
            localStorage.getItem(STORAGE_SMS)
          );
          const { data } = await talentRequest({
            url: "/api/sms/verify/",
            method: "POST",
            data: {
              phone,
              secure_code: code,
              session_token,
            },
          });

          this.$emit("submit", phone, data.session_token);
          this.errorPhone = "";
          localStorage.removeItem(STORAGE_SMS);
          this.isEdit = false;
          this.step = 1;
          this.clearTimer();
        } catch (error) {
          this.errorCode = error.message;
        }
        this.isPendingCode = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.input-icon {
  cursor: pointer;
  color: @blue-menu;

  &:hover {
    color: @link-blue;
  }
}
.confirm-tag {
  line-height: @input-height;
}
</style>
